<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M6.707 1.707A1 1 0 0 0 5.293.293l-5 5a1 1 0 0 0 0 1.414l5 5a1 1 0 1 0 1.414-1.414L2.414 6l4.293-4.293z"
    />
    <path d="M2 7h22a1 1 0 0 0 0-2H2a1 1 0 1 0 0 2z" />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
